import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import * as es from '../Translations/es.json'
import * as en from '../Translations/en.json'
import * as pt from '../Translations/pt.json'
import * as ca from '../Translations/ca.json'
import * as fr from '../Translations/fr.json'
import * as de from '../Translations/de.json'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    es: {translation: es},
    en: {translation: en},
    pt: {translation: pt},
    ca: {translation: ca},
    de: {translation: de},
    fr: {translation: fr}
};

i18n.use(initReactI18next).init({
    resources,
    lng: "es", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    fallbackLng: "en",
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
        escapeValue: false // react already safes from xss
    }
});

export default i18n;
